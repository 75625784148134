<div class="portfolioSection">
    <div class="headline">
        <div class="lineOne"></div>
        <span class="headlineSpan1">Portfolio</span>
        <div class="lineTwo"></div>
    </div>
    <span class="interactionSpan">{{ getTranslation('projectSelection') }}</span>
    <div class="project">
        <img class="projectPic" src="assets/img/coffee-2425303_640.jpg" alt="">
        <div class="projectSpans">
            <span class="headlineSpan">JOIN</span>
            <span class="stackSpan">JavaScript | HTML | CSS</span>
            <span class="projectTextSpan">{{ getTranslation('projectDescription') }}</span>
            <div class="testButtonSection">
                <a class="liveTestButton" href="https://pascal-megnin.developerakademie.net/JoinProject/log-in.html">Live test</a>
                <a class="gitButton" href="https://github.com/Pascalina1985/JoinProjectKanban">Github</a>
            </div>
        </div>
    </div>
    <img class="shadow3" src="assets/img/Purple shadow 2.png" alt="">
    <div class="project">
        <div class="projectSpans polloSpanSection">
            <span class="headlineSpan">El pollo loco</span>
            <span class="stackSpan">JavaScript | HTML | CSS</span>
            <span class="projectTextSpan" [innerHTML]="getTranslation('jumpAndRunGameDescription')"></span>
            <div class="testButtonSection">
                <a class="liveTestButton" href="http://pascal-megnin.developerakademie.net/El%20Pollo%20Loco/index.html">Live test</a>
                <a class="gitButton" href="https://github.com/Pascalina1985/PepeGoesLocoGame">Github</a>
            </div>
        </div>
        <img class="projectPic" src="assets/img/coffeepollo.jpg" alt="">
    </div>
    <div class="project">
        <img class="projectPic" src="assets/img/coffeepoke.jpg" alt="">
        <div class="projectSpans">
            <span class="headlineSpan">Pokedex</span>
            <span class="stackSpan">Javascript | HTML | CSS | API</span>
            <span class="projectTextSpan" [innerHTML]="getTranslation('apiIntegrationDescription')"></span>
            <div class="testButtonSection">
                <a class="liveTestButton" href="http://pascal-megnin.developerakademie.net/05%20Pokedex/index.html">Live test</a>
                <a class="gitButton" href="https://github.com/Pascalina1985/pokeAPIproject">Github</a>
            </div>
        </div>
    </div>
</div>