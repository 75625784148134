<div class="aboutSection">
    <div class="formatResponsive">
        <div class="aboutResponsive">
            <span class="aboutMe">About me</span>
            <span class="aaa">{{ getTranslation('introductionDescription') }}</span>
            <div class="newStructure">
                <img class="structurePic" src="assets/img/Location.png" alt="">
                <span class="textDeco">{{ getTranslation('educationDescription') }} </span>
            </div>
            <div class="newStructure">
                <img class="structurePic" src="assets/img/bulb.png" alt="">
                <span class="textDeco">{{ getTranslation('certificationDescription') }}</span>
            </div>
            <div class="newStructure">
                <img class="structurePic" src="assets/img/puzzle.png" alt="">
                <span class="textDeco">{{ getTranslation('personalQualities') }}</span>
            </div>
        </div>
        <img class="meAboutPicture" src="assets/img/WebPage.png" alt="">
    </div>
    <img class="purple2" src="assets/img/Purple shadow 2.png" alt="">
</div>