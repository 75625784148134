<div class="footer">
    <div class="newFooterFormat">
        <div class="labelSection">
            <span class="label">Pascal Megnin</span>
            <a routerLink="/impressum">Impressum</a>
            <a routerLink="/datenschatz">Datenschutz</a>
        </div>
        <div>
            <span class="copyright">&#169; Pascal Megnin 2024</span>
        </div>
        <div class="iconContainer">
            <a href="https://github.com/Pascalina1985?tab=repositories" target="_blank">
                <img class="iconPicture" src="assets/img/git.png" alt="GitHub">
            </a>
            <a href="mailto:pascalmegnin1985@gmail.com">
                <img class="iconPicture" src="assets/img/mail.png" alt="Email">
            </a>
            <a href="https://www.xing.com/profile/Pascal_Megnin/web_profiles" target="_blank">
                <img class="iconPicture" src="assets/img/linkedin.png" alt="LinkedIn">
            </a>
        </div>
    </div>
</div>