import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslationService } from '../translation.service'; // Importiere den TranslationService
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault(); 
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); 
        } else {
          console.warn(`Section with ID '${sectionId}' not found.`);
        }
      }, 100); 
    });
  }

  menuOpen: boolean = false;
  selectedLanguage: string = 'de';

  constructor(private translationService: TranslationService, private router: Router) { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu(rudy: string) {
    if (rudy === 'direct') {
      this.menuOpen = false;
    } else if (rudy === 'switch') {
      setTimeout(() => {
        this.menuOpen = false;
      }, 1100);
    }
    
  }

  // Übersetzung abrufen
  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  // Sprache umschalten
  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
    this.selectedLanguage = lang;
    }
  }
