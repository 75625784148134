<div class="skillSection">
    <div class="newDivFormat">
        <div class="orderSection">
            <div class="newFormatDivStructure">
                <img class="skillPicture" src="assets/img/html.png" alt="">
                <img class="skillPicture" src="assets/img/css.png" alt="">
                <img class="skillPicture" src="assets/img/javascript.png" alt="">
                <img class="skillPicture" src="assets/img/typescript.png" alt="">
            </div>
            <div class="newFormatDivStructure">
                <img class="skillPicture" src="assets/img/angular.png" alt="">
                <img class="skillPicture" src="assets/img/firebase.png" alt="">
                <img class="skillPicture" src="assets/img/git2.png" alt="">
                <img class="skillPicture" src="assets/img/restapi.png" alt="">
            </div>
            <div class="newFormatDivStructure">
                <img class="skillPicture" src="assets/img/scrum.png" alt="">
                <img class="skillPicture" src="assets/img/materialdesign.png" alt="">
                <img class="skillPicture" src="assets/img/lernen.png" alt=""></div>
        </div>
        <div class="textArea">
            <div class="newFormatDivStructure" class="skillslinearea">
                <span class="myskills">My skills</span>
                <div class="line"></div>
            </div>
            <span class="uebungSpan">{{ getTranslation('loveBuildingFrontends') }}</span>
            <span class="skillSpan" [innerHTML]="getTranslation('lookingForSkills')"></span>
            <span class="freeSpan" [innerHTML]="getTranslation('feelFreeToContact')"></span>
            <a class="contactMe" href="#contact" (click)="scrollToSection($event, 'contact')">{{ getTranslation('contact') }} :-)</a>
        </div>
    </div>
    <img class="shadow2" src="assets/img/Green shadow 2.png" alt="">
</div>