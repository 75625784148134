import { Component } from '@angular/core';
import { MainComponent } from '../main/main.component';
import { AboutmeComponent } from '../aboutme/aboutme.component';
import { SkillsComponent } from '../skills/skills.component';
import { ProjectsComponent } from '../projects/projects.component';
import { ContactComponent } from '../contact/contact.component';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-mainmain',
  standalone: true,
  imports: [MainComponent, AboutmeComponent, SkillsComponent, SkillsComponent, ProjectsComponent, ContactComponent, HeaderComponent],
  templateUrl: './mainmain.component.html',
  styleUrl: './mainmain.component.scss'
})
export class MainmainComponent {

}
