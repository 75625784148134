import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../translation.service'; 
import { MainmainComponent } from '../mainmain/mainmain.component';

@Component({
  selector: 'app-datenschatz',
  standalone: true,
  imports: [MainmainComponent],
  templateUrl: './datenschatz.component.html',
  styleUrls: ['./datenschatz.component.scss']
})
export class DatenschatzComponent {
  constructor(private translationService: TranslationService, private router: Router) { }

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }

    
  goToHome() {
    this.router.navigate(['/']); 
  }
}

