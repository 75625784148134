<div class="datenschutz">
    <button (click)="goToHome()" class="backButton">Zurück</button>
    <h1>{{ getTranslation('privacyPolicyTitle') }}</h1>
    <h2 id="m716">{{ getTranslation('privacyPolicyIntroduction') }}</h2>
    <p>{{ getTranslation('privacyPolicyPreamble') }}</p>
    <p>{{ getTranslation('privacyPolicyGenderNeutral') }}</p>

    <p>5. September 2024</p>
    <h2>{{ getTranslation('privacyPolicyTableOfContents') }}</h2>
    <ul class="index">
        <li><a class="index-link" href="#m716">{{ getTranslation('privacyPolicySection1') }}</a></li>
        <li><a class="index-link" href="#m3">{{ getTranslation('privacyPolicySection2') }}</a></li>
        <li><a class="index-link" href="#mOverview">{{ getTranslation('privacyPolicySection3') }}</a></li>
        <li><a class="index-link" href="#m2427">{{ getTranslation('privacyPolicySection4') }}</a></li>
        <li><a class="index-link" href="#m27">{{ getTranslation('privacyPolicySection5') }}</a></li>
        <li><a class="index-link" href="#m25">{{ getTranslation('privacyPolicySection6') }}</a></li>
        <li><a class="index-link" href="#m24">{{ getTranslation('privacyPolicySection7') }}</a></li>
        <li><a class="index-link" href="#m12">{{ getTranslation('privacyPolicySection8') }}</a></li>
        <li><a class="index-link" href="#m10">{{ getTranslation('privacyPolicySection9') }}</a></li>
        <li><a class="index-link" href="#m225">{{ getTranslation('privacyPolicySection10') }}</a></li>
    </ul>
    <p>Pascal Megnin<br>Welfenallee 21<br>69181 Leimen</p>
    <p>E-Mail-Adresse: <a href="mailto:pascalmegnin1985@gmail.com">pascalmegnin1985&#64;gmail.com</a></p>

    <h2 id="mOverview">{{ getTranslation('privacyPolicyOverviewTitle') }}</h2>
    <p>{{ getTranslation('privacyPolicyOverviewDescription') }}</p>
    <h3>{{ getTranslation('privacyPolicyDataTypes') }}</h3>
    <ul>
        <li>{{ getTranslation('privacyPolicyDataType1') }}</li>
        <li>{{ getTranslation('privacyPolicyDataType2') }}</li>
        <li>{{ getTranslation('privacyPolicyDataType3') }}</li>
        <li>{{ getTranslation('privacyPolicyDataType4') }}</li>
        <li>{{ getTranslation('privacyPolicyDataType5') }}</li>
        <li>{{ getTranslation('privacyPolicyDataType6') }}</li>
    </ul>

    <h3 id="affectedPersons">{{ getTranslation('affectedPersonsTitle') }}</h3>
    <ul>
        <li>{{ getTranslation('affectedPerson1') }}</li>
        <li>{{ getTranslation('affectedPerson2') }}</li>
    </ul>

    <h3 id="processingPurposes">{{ getTranslation('processingPurposesTitle') }}</h3>
    <ul>
        <li>{{ getTranslation('processingPurpose1') }}</li>
        <li>{{ getTranslation('processingPurpose2') }}</li>
        <li>{{ getTranslation('processingPurpose3') }}</li>
        <li>{{ getTranslation('processingPurpose4') }}</li>
        <li>{{ getTranslation('processingPurpose5') }}</li>
        <li>{{ getTranslation('processingPurpose6') }}</li>
    </ul>

    <h2 id="m2427">{{ getTranslation('legalBasisTitle') }}</h2>
    <p [innerHTML]="getTranslation('legalBasisDescription1')"></p>
    <ul>
        <li [innerHTML]="getTranslation('legalBasis1')"></li>
        <li [innerHTML]="getTranslation('legalBasis2')"></li>
    </ul>
    <p [innerHTML]="getTranslation('legalBasisDescription2')"></p>
    <p [innerHTML]="getTranslation('legalBasisDescription3')"></p>


    <h2 id="m27">{{ getTranslation('securityMeasuresTitle') }}</h2>
    <p [innerHTML]="getTranslation('securityMeasuresDescription1')"></p>
    <p [innerHTML]="getTranslation('securityMeasuresDescription2')"></p>


    <h2 id="m25">{{ getTranslation('dataTransferTitle') }}</h2>
    <p [innerHTML]="getTranslation('dataTransferDescription')"></p>


    <h2 id="m12">{{ getTranslation('dataRetentionTitle') }}</h2>
    <p>{{ getTranslation('dataRetentionDescription1') }}</p>
    <p>{{ getTranslation('dataRetentionDescription2') }}</p>
    <p>{{ getTranslation('dataRetentionDescription3') }}</p>
    <p>{{ getTranslation('dataRetentionDescription4') }}</p>
    <p>{{ getTranslation('dataRetentionDescription5') }}</p>
    <p>{{ getTranslation('dataRetentionDescription6') }}</p>
    <p><strong>{{ getTranslation('dataRetentionDescription7') }}</strong></p>

    <ul class="m-elements">
        <li><strong>{{ getTranslation('retentionPeriodsTitle') }}:</strong>
            <ul>
                <li>{{ getTranslation('retentionPeriod1') }}</li>
                <li>{{ getTranslation('retentionPeriod2') }}</li>
                <li>{{ getTranslation('retentionPeriod3') }}</li>
            </ul>
        </li>
    </ul>
    <h2 id="m10">{{ getTranslation('dataSubjectRightsTitle') }}</h2>
    <p>{{ getTranslation('dataSubjectRightsIntro') }}</p>
    <ul>
        <li><strong>{{ getTranslation('rightToObject') }}</strong></li>
        <li><strong>{{ getTranslation('rightToWithdrawConsent') }}</strong></li>
        <li><strong>{{ getTranslation('rightToAccess') }}</strong></li>
        <li><strong>{{ getTranslation('rightToRectification') }}</strong></li>
        <li><strong>{{ getTranslation('rightToErasureRestriction') }}</strong></li>
        <li><strong>{{ getTranslation('rightToDataPortability') }}</strong></li>
        <li><strong>{{ getTranslation('rightToLodgeComplaint') }}</strong></li>
    </ul>


    <<h2 id="m225">{{ getTranslation('onlineOfferHostingTitle') }}</h2>
        <p>{{ getTranslation('onlineOfferHostingIntro') }}</p>
        <ul class="m-elements">
            <li><strong>{{ getTranslation('dataTypesProcessed') }}</strong></li>
            <li><strong>{{ getTranslation('affectedPersons') }}</strong></li>
            <li><strong>{{ getTranslation('processingPurposes') }}</strong></li>
            <li><strong>{{ getTranslation('storageErasure') }}</strong></li>
            <li><strong>{{ getTranslation('legalBasis') }}</strong></li>
        </ul>

        <p><strong>{{ getTranslation('processingNotesTitle') }}</strong></p>
        <ul class="m-elements">
            <li><strong>{{ getTranslation('logDataCollection') }}</strong></li>
        </ul>

        <h2 id="m134">{{ getTranslation('cookiesTitle') }}</h2>
        <p>{{ getTranslation('cookiesDescription') }}</p>
        <p><strong>{{ getTranslation('consentNotes') }}</strong></p>
        <p><strong>{{ getTranslation('legalBasisNotes') }}</strong></p>
        <p><strong>{{ getTranslation('storageDuration') }}</strong></p>

        <ul>
            <li><strong>{{ getTranslation('temporaryCookieTitle') }}</strong> {{ getTranslation('temporaryCookieDescription') }}</li>
            <li><strong>{{ getTranslation('permanentCookieTitle') }}</strong> {{ getTranslation('permanentCookieDescription') }}</li>
        </ul>

        <p><strong>{{ getTranslation('optOutNotes') }}</strong></p>
        <ul class="m-elements">
            <li><strong>{{ getTranslation('processedDataTypes') }}</strong></li>
            <li><strong>{{ getTranslation('aaffectedPersons') }}</strong></li>
            <li><strong>{{ getTranslation('legalBases') }}</strong></li>
        </ul>

        <p><strong>{{ getTranslation('additionalProcessingNotes') }}</strong></p>
        <ul class="m-elements">
            <li><strong>{{ getTranslation('cookieDataConsentProcessing') }}</strong> {{ getTranslation('cookieDataConsentDescription') }} <span class=""><strong>{{ getTranslation('alegalBasis') }}:</strong> {{ getTranslation('consentLegalBasis') }}</span></li>
        </ul>

        <p class="seal">
            <a href="https://datenschutz-generator.de/" title="Legal text by Dr. Schwenke - click for more information." target="_blank" rel="noopener noreferrer nofollow">
                {{ getTranslation('sealText') }}
            </a>
        </p>

</div>