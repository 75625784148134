import { Injectable } from '@angular/core';

type Translations = {
  [key: string]: { [key: string]: string };
};

@Injectable({
  providedIn: 'root', // Der Service wird in der gesamten App verfügbar sein
})
export class TranslationService {
  // Übersetzungen mit dem neuen Typ versehen
  private translations: Translations = {
    en: {
      contact: 'Contact',
      introduction: 'I am',
      introductionDescription: 'I am a dedicated Front-End Developer with deep knowledge in Angular and JavaScript. Through creating professional business apps and websites, I have been able to expand and deepen my knowledge both independently and in teamwork.',
      educationDescription: 'The training at the Developer Academy allowed me to elevate my knowledge to a professional level, which you can see in my linked projects. I look forward to contributing my technical skills to a creative team and helping develop innovative solutions.',
      certificationDescription: 'My successful certification as a web developer was inspired by programming my own projects beforehand. My personal skills, such as analytical thinking and solution-oriented action, particularly contributed to this.',
      personalQualities: 'When it comes to meaningful goals, I definitely go the extra mile. I am loyal, highly motivated, and strong in communication.',
      loveBuildingFrontends: 'I love building user-friendly frontends with various technologies.',
      lookingForSkills: 'Are you looking for <span class="colorSpan">other skills</span>?',
      feelFreeToContact: 'Feel free to contact me. I am always interested in <br>expanding my skills and knowledge',
      projectSelection: 'Here is a selection of my projects. I hope you enjoy interacting with them.',
      projectDescription: 'Task management application based on the Kanban principle. Create and organize tasks with drag-and-drop functionality. User assignment to categories.',
      jumpAndRunGameDescription: 'An object-oriented jump and run game.<br>Help the crazy Mexican fight against<br>chickens. Collect bottles as throwable weapons.',
      apiIntegrationDescription: 'Information from REST API.<br>Implementation of features in the frontend.',
      contactMessage1: 'Feel free to contact me :-)! I look forward<br>to hearing from you.',
      contactMessage2: 'Need a front-end developer? Contact me.',
      privacyPolicyAgreement: 'I agree to the privacy policy',
      nameRequired: 'Please enter your name!',
      emailRequired: 'Please enter your email!',
      messageRequired: 'Please enter your message!',
      agreeToPrivacyPolicy: 'Please agree to the privacy policy.',
      successMessage: 'Message successfully sent!',
      privacyPolicyTitle: 'Privacy Policy',
      privacyPolicyPreamble: 'With the following privacy policy...',
      privacyPolicyGenderNeutral: 'The terms used are not gender-specific.',
      privacyPolicyIntroduction: 'Preamble',
      privacyPolicyTableOfContents: 'Table of Contents', // Hinzugefügt
      privacyPolicySection1: 'Responsible Party', // Hinzugefügt
      privacyPolicySection2: 'Overview of Processing', // Hinzugefügt
      privacyPolicySection3: 'Relevant Legal Bases', // Hinzugefügt
      privacyPolicySection4: 'Security Measures', // Hinzugefügt
      privacyPolicySection5: 'Transmission of Personal Data', // Hinzugefügt
      privacyPolicySection6: 'International Data Transfers', // Hinzugefügt
      privacyPolicySection7: 'General Information on Data Storage and Deletion', // Hinzugefügt
      privacyPolicySection8: 'Rights of Affected Persons', // Hinzugefügt
      privacyPolicySection9: 'Provision of the Online Offer and Web Hosting', // Hinzugefügt
      privacyPolicySection10: 'Use of Cookies',
      privacyPolicyOverviewTitle: 'Overview of Processing',
      privacyPolicyOverviewDescription: 'The following overview summarizes the types of processed data and the purposes of their processing and refers to the affected persons.',
      privacyPolicyDataTypes: 'Types of Processed Data',
      privacyPolicyDataType1: 'Inventory data.',
      privacyPolicyDataType2: 'Contact data.',
      privacyPolicyDataType3: 'Content data.',
      privacyPolicyDataType4: 'Usage data.',
      privacyPolicyDataType5: 'Meta, communication, and procedural data.',
      privacyPolicyDataType6: 'Log data.',
      affectedPersonsTitle: 'Categories of Affected Persons',
      affectedPerson1: 'Communication partners.',
      affectedPerson2: 'Users.',
      processingPurposesTitle: 'Purposes of Processing',
      processingPurpose1: 'Communication.',
      processingPurpose2: 'Security measures.',
      processingPurpose3: 'Organizational and administrative processes.',
      processingPurpose4: 'Feedback.',
      processingPurpose5: 'Provision of our online offer and user-friendliness.',
      processingPurpose6: 'IT infrastructure.',
      legalBasisTitle: 'Relevant Legal Bases',
      legalBasisDescription1: '<strong>Relevant legal bases under the GDPR:</strong> Below you will find an overview of the legal bases of the GDPR on which we process personal data. Please note that in addition to the GDPR, national data protection regulations may apply in your or our country of residence or registered office. If more specific legal bases apply in individual cases, we will inform you of these in the privacy policy.',
      legalBasis1: '<strong>Consent (Art. 6(1)(a) GDPR)</strong> – The data subject has given consent to the processing of personal data for one or more specific purposes.',
      legalBasis2: '<strong>Legitimate Interests (Art. 6(1)(f) GDPR)</strong> – The processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, provided that such interests are not overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data.',
      legalBasisDescription2: '<strong>National Data Protection Regulations in Germany:</strong> In addition to the data protection regulations of the GDPR, national regulations on data protection in Germany apply. This includes the Federal Data Protection Act (Bundesdatenschutzgesetz - BDSG), which contains special regulations on the right to information, deletion, objection, the processing of special categories of personal data, processing for other purposes, and transmission, as well as automated individual decision-making including profiling. Additionally, the data protection laws of the individual German states may apply.',
      legalBasisDescription3: '<strong>Note on the applicability of GDPR and Swiss DPA:</strong> These privacy notices serve both the information requirements under the Swiss DPA and the GDPR. For this reason, we would like to point out that due to broader spatial application and comprehensibility, the terms used in the GDPR are used here. In particular, instead of the terms "processing" of "personal data," "legitimate interest," and "special categories of data" used in the GDPR, the equivalent terms from the Swiss DPA will be applied when applicable.',
      securityMeasuresTitle: 'Security Measures',
      securityMeasuresDescription1: 'In accordance with legal requirements, taking into account the state of the art, implementation costs, and the nature, scope, circumstances, and purposes of processing, as well as the varying likelihood and severity of risks to the rights and freedoms of natural persons, we take appropriate technical and organizational measures to ensure a level of security appropriate to the risk.',
      securityMeasuresDescription2: 'Measures include securing the confidentiality, integrity, and availability of data by controlling physical and electronic access to the data as well as access, input, transmission, securing availability, and separation of the data itself. We have also established procedures to ensure the exercise of data subject rights, deletion of data, and response to data risks. Furthermore, we take into account the protection of personal data in the development or selection of hardware, software, and procedures, in line with the principle of data protection by design and by default.',
      dataTransferTitle: 'Transfer of Personal Data',
      dataTransferDescription: 'In the course of our processing of personal data, it may happen that such data is transferred to other entities, companies, legally independent organizational units, or individuals, or disclosed to them. Recipients of this data may include service providers tasked with IT tasks or providers of services and content that are integrated into a website. In such cases, we observe the legal requirements and conclude corresponding contracts or agreements that serve to protect your data with the recipients of your data.',
      dataRetentionTitle: 'General Information on Data Retention and Deletion',
      dataRetentionDescription1: 'We delete personal data that we process in accordance with legal requirements as soon as the underlying consents are revoked or there are no further legal bases for processing. This applies to cases where the original purpose of processing ceases to exist or the data is no longer needed. Exceptions to this rule apply when legal obligations or special interests require longer retention or archiving of the data.',
      dataRetentionDescription2: 'In particular, data that must be retained for commercial or tax reasons, or whose retention is necessary for legal prosecution or the protection of the rights of other natural or legal persons, must be archived accordingly.',
      dataRetentionDescription3: 'Our privacy notices contain additional information about the retention and deletion of data, which apply specifically to certain processing processes.',
      dataRetentionDescription4: 'If there are multiple retention periods or deletion deadlines for a date, the longest period is always decisive.',
      dataRetentionDescription5: 'If a deadline does not explicitly start on a specific date and is at least one year long, it automatically starts at the end of the calendar year in which the event triggering the deadline occurred. In the case of ongoing contractual relationships where data is stored, the event triggering the deadline is the effective date of the termination or other termination of the legal relationship.',
      dataRetentionDescription6: 'Data that is no longer retained for its originally intended purpose, but due to legal requirements or other reasons, is processed solely for the reasons that justify its retention.',
      dataRetentionDescription7: 'Additional notes on processing processes, procedures, and services:',
      retentionPeriodsTitle: 'Retention and Deletion of Data',
      retentionPeriod1: '10 years - Retention period for books and records, annual financial statements, inventories, management reports, opening balance sheets, and the working instructions and other organizational documents required for their understanding, booking vouchers, and invoices (§ 147 Abs. 3 in conjunction with Abs. 1 No. 1, 4 and 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1 No. 1 and 4, Abs. 4 HGB).',
      retentionPeriod2: '6 years - Other business documents: received business or commercial letters, copies of sent business or commercial letters, other documents that are important for taxation, such as hourly wage slips, operational accounting sheets, calculation documents, price tags, but also wage accounting documents unless they are already booking receipts, and cash register strips (§ 147 Abs. 3 in conjunction with Abs. 1 No. 2, 3, 5 AO, § 257 Abs. 1 No. 2 and 3, Abs. 4 HGB).',
      retentionPeriod3: '3 years - Data required to consider potential warranty and damage compensation claims or similar contractual claims and rights and to process associated inquiries, based on past business experiences and common industry practices, are stored for the duration of the regular statutory limitation period of three years (§§ 195, 199 BGB).',
      dataSubjectRightsTitle: 'Rights of Data Subjects',
      dataSubjectRightsIntro: 'Rights of data subjects under the GDPR: As a data subject, you have various rights under the GDPR, which are primarily outlined in Articles 15 to 21 GDPR:',
      rightToObject: 'Right to object: You have the right, for reasons arising from your particular situation, to object at any time to the processing of your personal data that is carried out on the basis of Article 6(1)(e) or (f) GDPR; this also applies to profiling based on these provisions. If your personal data is processed for direct marketing purposes, you have the right to object at any time to the processing of your personal data for the purposes of such marketing; this also applies to profiling related to direct marketing.',
      rightToWithdrawConsent: 'Right to withdraw consent: You have the right to withdraw any consent you have given at any time.',
      rightToAccess: 'Right to access: You have the right to request confirmation as to whether personal data concerning you is being processed and to receive information about such data, as well as further information and a copy of the data, in accordance with the legal provisions.',
      rightToRectification: 'Right to rectification: You have the right, in accordance with the legal provisions, to request the completion or correction of inaccurate data concerning you.',
      rightToErasureRestriction: 'Right to erasure and restriction of processing: You have the right, in accordance with the legal provisions, to request that the data concerning you be erased immediately, or alternatively, in accordance with the legal provisions, to request a restriction of processing.',
      rightToDataPortability: 'Right to data portability: You have the right to receive the data concerning you, which you have provided to us, in a structured, commonly used, and machine-readable format, or to request the transfer of this data to another controller, in accordance with the legal provisions.',
      rightToLodgeComplaint: 'Right to lodge a complaint with a supervisory authority: Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority, in particular in the member state of your habitual residence, place of work, or the location of the alleged infringement, if you believe that the processing of personal data concerning you violates the provisions of the GDPR.',
      onlineOfferHostingTitle: 'Provision of the Online Offer and Web Hosting',
      onlineOfferHostingIntro: 'We process users\' data to provide our online services. For this purpose, we process the user\'s IP address, which is necessary to deliver the content and features of our online services to the user\'s browser or device.',
      dataTypesProcessed: 'Processed data types: Usage data (e.g. page views and dwell time, click paths, usage intensity and frequency, types of devices and operating systems used, interactions with content and features); meta, communication, and procedural data (e.g. IP addresses, timestamps, identification numbers, involved persons). Log data (e.g. log files related to logins or data retrieval or access times).',
      affectedPersons: 'Affected persons: Users (e.g. website visitors, users of online services).',
      processingPurposes: 'Purposes of processing: Provision of our online offer and user-friendliness; information technology infrastructure (operation and provision of information systems and technical devices (computers, servers, etc.)); security measures.',
      storageErasure: 'Retention and deletion: Deletion as described in the section "General Information on Data Storage and Deletion."',
      legalBasis: 'Legal basis: Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR).',
      processingNotesTitle: 'Further Notes on Processing Processes, Procedures, and Services',
      logDataCollection: 'Collection of Access Data and Log Files: Access to our online services is logged in the form of "server log files." Server log files may include the address and name of the retrieved web pages and files, date and time of retrieval, amount of data transferred, notification of successful retrieval, browser type and version, the user\'s operating system, referrer URL (the previously visited page), and usually IP addresses and the requesting provider. Server log files can be used for security purposes, e.g., to prevent server overload (especially in the case of abusive attacks, so-called DDoS attacks), and also to ensure server load and stability; <strong>Legal basis:</strong> Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR). <strong>Data deletion:</strong> Log file information is stored for a maximum of 30 days and then deleted or anonymized. Data that is required for evidentiary purposes will be exempt from deletion until the respective incident is fully clarified.',
      cookiesTitle: 'Use of Cookies',
      cookiesDescription: 'Cookies are small text files or other storage notes that store and read information on devices. For example, they can store the login status in a user account, the contents of a shopping cart in an e-shop, the accessed content, or the functions used in an online service. Cookies can also be used for various purposes, such as functionality, security, and convenience of online services, as well as for analyzing visitor traffic.',
      consentNotes: 'Notes on Consent: We use cookies in accordance with legal regulations. Therefore, we obtain prior consent from users unless it is not required by law. Permission is not necessary if storing and reading the information, including cookies, is strictly necessary to provide the online service expressly requested by the users. The revocable consent is communicated clearly and includes information about the respective cookie usage.',
      legalBasisNotes: 'Notes on Legal Grounds: The legal basis for processing users\' personal data using cookies depends on whether we ask for consent. If users accept, the legal basis for processing their data is the declared consent. Otherwise, data processed using cookies will be based on our legitimate interests (e.g., in the economic operation of our online services and improving their usability) or, if this occurs in the context of fulfilling our contractual obligations, when the use of cookies is necessary to meet our contractual commitments.',
      storageDuration: 'Storage Duration: Regarding storage duration, the following types of cookies are distinguished:',
      temporaryCookieTitle: 'Temporary Cookies (Session Cookies):',
      temporaryCookieDescription: 'Temporary cookies are deleted as soon as a user leaves an online service and closes their device (e.g., browser or mobile application).',
      permanentCookieTitle: 'Permanent Cookies:',
      permanentCookieDescription: 'Permanent cookies remain stored even after closing the device. For example, they can save the login status and display preferred content directly when the user revisits a website. Data collected via cookies may also be used for reach measurement. Unless we provide explicit information about the type and storage duration of cookies, users should assume that these are permanent and may be stored for up to two years.',
      optOutNotes: 'General Notes on Revocation and Opposition (Opt-out): Users can revoke their given consent at any time and also express an objection to the processing in accordance with legal regulations, including via their browser\'s privacy settings.',
      processedDataTypes: 'Processed Data Types: Meta, communication, and procedural data (e.g., IP addresses, timestamps, identification numbers, involved persons).',
      aaffectedPersons: 'Affected Persons: Users (e.g., website visitors, users of online services).',
      legalBases: 'Legal Bases: Legitimate interests (Art. 6 para. 1 sentence 1 lit. f) GDPR). Consent (Art. 6 para. 1 sentence 1 lit. a) GDPR).',
      additionalProcessingNotes: 'Further notes on processing processes, procedures, and services:',
      cookieDataConsentProcessing: 'Processing of cookie data based on consent:',
      cookieDataConsentDescription: 'We use a consent management solution, where the consent of users for the use of cookies or for the processes and providers mentioned in the consent management solution is obtained. This procedure serves to obtain, log, manage, and withdraw consent, particularly concerning the use of cookies and comparable technologies that are used to store, read, and process information on users\' devices. In this process, user consents for the use of cookies and the associated processing of information are obtained, including the specific processes and providers mentioned in the consent management process. Users also have the option to manage and withdraw their consents. The consent statements are stored to avoid repeated queries and to maintain proof of consent in accordance with legal requirements. Storage is done server-side and/or in a cookie (so-called opt-in cookie) or using comparable technologies to assign the consent to a specific user or their device. If no specific information about the providers of consent management services is available, the following general information applies: The duration of consent storage is up to two years. A pseudonymous user identifier is created, which is stored along with the time of consent, the details of the scope of consent (e.g., relevant categories of cookies and/or service providers), as well as information about the browser, the system, and the device used.',
      alegalBasis: 'Legal basis',
      consentLegalBasis: 'Consent (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)',
      sealText: 'Created with free Privacy Generator by Dr. Thomas Schwenke',
      imprintTitle: 'Imprint',
      imprintAddress: 'Pascal Megnin Welfenallee 21 2 69181 Leimen',
      contactTitle: 'Contact',
      contactInfo: 'Phone: 015142894562 Email: pascalmegnin1985@gmail.com',
      editorialResponsibility: 'Editorial Responsibility',
      editorialPerson: 'Pascal Megnin',
      source: 'Source: <a class="link" href="https://www.e-recht24.de">https://www.e-recht24.de</a>',
      backButton: 'Back',
      sendLabel: 'Submit'
      },
    de: {
      contact: 'Kontakt',
      introduction: 'Ich bin',
      introductionDescription: 'Ich bin ein engagierter Front-End Developer mit fundierten Kenntnissen in Angular und JavaScript. Mit der Erstellung professioneller Business-Apps und Websites habe ich sowohl eigenständig, als auch in Teamarbeit mein Wissen ausbauen und vertiefen können.',
      educationDescription: 'Die Weiterbildung an der Developer Akademie ermöglichte es mir, mein Know-how auf ein professionelles Level zu erhöhen, wovon Sie sich in meinen verlinkten Projekten überzeugen können. Ich freue mich darauf, meine technischen Fähigkeiten in einem kreativen Team einzubringen und zur Entwicklung innovativer Lösungen beizutragen.',
      certificationDescription: 'Meine erfolgreiche Zertifizierung zum Webentwickler wurde schon vorab durch das Programmieren eigener Projekte inspiriert. Dazu trugen meine persönlichen Fähigkeiten wie analytisches Denken und lösungsorientiertes Handeln besonders bei.',
      personalQualities: 'Wenn es um sinnvolle Ziele geht, gehe ich definitiv die Extrameile. Ich bin loyal, hoch motiviert und kommunikationsstark.',
      loveBuildingFrontends: 'Ich liebe es, mit verschiedensten Technologien nutzerfreundliche Frontends zu bauen.',
      lookingForSkills: 'Sie schauen nach <span class="colorSpan">anderen Fähigkeiten</span>?',
      feelFreeToContact: 'Fühlen Sie sich frei, mich zu kontaktieren. Ich bin immer daran interessiert, <br>meine Fähigkeiten und mein Wissen auszubauen',
      projectSelection: 'Hier ist eine Auswahl meiner Projekte. Ich wünsche viel Spaß beim Interagieren.',
      projectDescription: 'Task Management Applikation nach Kanban-Prinzip. Erstellen und Organisieren von Aufgaben mit Drag-and-Drop-Funktionalität. Benutzerzuordnung zu Kategorien.',
      jumpAndRunGameDescription: 'Ein Jump and Run Spiel objektorientiert.<br>Hilf dem verrückten Mexikaner im Kampf gegen<br>Hühnchen. Sammle Flaschen als Wurfwaffe.',
      apiIntegrationDescription: 'Informationen aus Rest API.<br>Implementierung der Eigenschaften im Frontend.',
      contactMessage1: 'Kontaktieren Sie mich sehr gerne :-)! Ich freue mich<br>sehr, von Ihnen zu hören.',
      contactMessage2: 'Sie brauchen einen Frontend-Entwickler? Kontaktieren Sie mich.',
      privacyPolicyAgreement: 'Ich erkläre mich mit den Datenschutzbestimmungen einverstanden',
      nameRequired: 'Bitte Name eingeben!',
      emailRequired: 'Bitte E-Mail eingeben!',
      messageRequired: 'Bitte Nachricht eingeben!',
      agreeToPrivacyPolicy: 'Bitte stimmen Sie der Datenschutzerklärung zu.',
      successMessage: 'Nachricht erfolgreich übermittelt',
      privacyPolicyTitle: 'Datenschutzerklärung',
      privacyPolicyPreamble: 'Mit der folgenden Datenschutzerklärung...',
      privacyPolicyGenderNeutral: 'Die verwendeten Begriffe sind nicht geschlechtsspezifisch.',
      privacyPolicyIntroduction: 'Präambel',
      privacyPolicyTableOfContents: 'Inhaltsübersicht', // Hinzugefügt
      privacyPolicySection1: 'Verantwortlicher', // Hinzugefügt
      privacyPolicySection2: 'Übersicht der Verarbeitungen', // Hinzugefügt
      privacyPolicySection3: 'Maßgebliche Rechtsgrundlagen', // Hinzugefügt
      privacyPolicySection4: 'Sicherheitsmaßnahmen', // Hinzugefügt
      privacyPolicySection5: 'Übermittlung von personenbezogenen Daten', // Hinzugefügt
      privacyPolicySection6: 'Internationale Datentransfers', // Hinzugefügt
      privacyPolicySection7: 'Allgemeine Informationen zur Datenspeicherung und Löschung', // Hinzugefügt
      privacyPolicySection8: 'Rechte der betroffenen Personen', // Hinzugefügt
      privacyPolicySection9: 'Bereitstellung des Onlineangebots und Webhosting', // Hinzugefügt
      privacyPolicySection10: 'Einsatz von Cookies',
      privacyPolicyOverviewTitle: 'Übersicht der Verarbeitungen',
      privacyPolicyOverviewDescription: 'Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.',
      privacyPolicyDataTypes: 'Arten der verarbeiteten Daten',
      privacyPolicyDataType1: 'Bestandsdaten.',
      privacyPolicyDataType2: 'Kontaktdaten.',
      privacyPolicyDataType3: 'Inhaltsdaten.',
      privacyPolicyDataType4: 'Nutzungsdaten.',
      privacyPolicyDataType5: 'Meta-, Kommunikations- und Verfahrensdaten.',
      privacyPolicyDataType6: 'Protokolldaten.',
      affectedPersonsTitle: 'Kategorien betroffener Personen',
      affectedPerson1: 'Kommunikationspartner.',
      affectedPerson2: 'Nutzer.',
      processingPurposesTitle: 'Zwecke der Verarbeitung',
      processingPurpose1: 'Kommunikation.',
      processingPurpose2: 'Sicherheitsmaßnahmen.',
      processingPurpose3: 'Organisations- und Verwaltungsverfahren.',
      processingPurpose4: 'Feedback.',
      processingPurpose5: 'Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.',
      processingPurpose6: 'Informationstechnische Infrastruktur.',
      legalBasisTitle: 'Maßgebliche Rechtsgrundlagen',
      legalBasisDescription1: '<strong>Maßgebliche Rechtsgrundlagen nach der DSGVO:</strong> Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.',
      legalBasis1: '<strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> – Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.',
      legalBasis2: '<strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> – die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten verlangen, nicht überwiegen.',
      legalBasisDescription2: '<strong>Nationale Datenschutzregelungen in Deutschland:</strong> Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.',
      legalBasisDescription3: '<strong>Hinweis auf Geltung DSGVO und Schweizer DSG:</strong> Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach dem Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.',
      securityMeasuresTitle: 'Sicherheitsmaßnahmen',
      securityMeasuresDescription1: 'Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.',
      securityMeasuresDescription2: 'Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.',
      dataTransferTitle: 'Übermittlung von personenbezogenen Daten',
      dataTransferDescription: 'Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass diese an andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in eine Website eingebunden sind. In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.',
      dataRetentionTitle: 'Allgemeine Informationen zur Datenspeicherung und Löschung',
      dataRetentionDescription1: 'Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß den gesetzlichen Bestimmungen, sobald die zugrundeliegenden Einwilligungen widerrufen werden oder keine weiteren rechtlichen Grundlagen für die Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche Verarbeitungszweck entfällt oder die Daten nicht mehr benötigt werden. Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten oder besondere Interessen eine längere Aufbewahrung oder Archivierung der Daten erfordern.',
      dataRetentionDescription2: 'Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der Rechte anderer natürlicher oder juristischer Personen, entsprechend archiviert werden.',
      dataRetentionDescription3: 'Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur Aufbewahrung und Löschung von Daten, die speziell für bestimmte Verarbeitungsprozesse gelten.',
      dataRetentionDescription4: 'Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die längste Frist maßgeblich.',
      dataRetentionDescription5: 'Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und beträgt sie mindestens ein Jahr, so startet sie automatisch am Ende des Kalenderjahres, in dem das fristauslösende Ereignis eingetreten ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten gespeichert werden, ist das fristauslösende Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder sonstige Beendigung des Rechtsverhältnisses.',
      dataRetentionDescription6: 'Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern aufgrund gesetzlicher Vorgaben oder anderer Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen, die ihre Aufbewahrung rechtfertigen.',
      dataRetentionDescription7: 'Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:',
      retentionPeriodsTitle: 'Aufbewahrung und Löschung von Daten',
      retentionPeriod1: '10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen, Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanz sowie die zu ihrem Verständnis erforderlichen Arbeitsanweisungen und sonstigen Organisationsunterlagen, Buchungsbelege und Rechnungen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 1, 4 und 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 u. 4, Abs. 4 HGB).',
      retentionPeriod2: '6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder Geschäftsbriefe, Wiedergaben der abgesandten Handels- oder Geschäftsbriefe, sonstige Unterlagen, soweit sie für die Besteuerung von Bedeutung sind, z. B. Stundenlohnzettel, Betriebsabrechnungsbögen, Kalkulationsunterlagen, Preisauszeichnungen, aber auch Lohnabrechnungsunterlagen, soweit sie nicht bereits Buchungsbelege sind und Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3, 5 AO, § 257 Abs. 1 Nr. 2 u. 3, Abs. 4 HGB).',
      retentionPeriod3: '3 Jahre - Daten, die erforderlich sind, um potenzielle Gewährleistungs- und Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und Rechte zu berücksichtigen sowie damit verbundene Anfragen zu bearbeiten, basierend auf früheren Geschäftserfahrungen und üblichen Branchenpraktiken, werden für die Dauer der regulären gesetzlichen Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199 BGB).',
      dataSubjectRightsTitle: 'Rechte der betroffenen Personen',
      dataSubjectRightsIntro: 'Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:',
      rightToObject: 'Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.',
      rightToWithdrawConsent: 'Widerrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.',
      rightToAccess: 'Auskunftsrecht: Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.',
      rightToRectification: 'Recht auf Berichtigung: Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.',
      rightToErasureRestriction: 'Recht auf Löschung und Einschränkung der Verarbeitung: Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.',
      rightToDataPortability: 'Recht auf Datenübertragbarkeit: Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.',
      rightToLodgeComplaint: 'Beschwerde bei Aufsichtsbehörde: Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.',
      onlineOfferHostingTitle: 'Bereitstellung des Onlineangebots und Webhosting',
      onlineOfferHostingIntro: 'Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.',
      dataTypesProcessed: 'Verarbeitete Datenarten: Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen). Protokolldaten (z. B. Logfiles betreffend Logins oder den Abruf von Daten oder Zugriffszeiten).',
      affectedPersons: 'Betroffene Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).',
      processingPurposes: 'Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server etc.)). Sicherheitsmaßnahmen.',
      storageErasure: 'Aufbewahrung und Löschung: Löschung entsprechend Angaben im Abschnitt "Allgemeine Informationen zur Datenspeicherung und Löschung".',
      legalBasis: 'Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).',
      processingNotesTitle: 'Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten',
      logDataCollection: 'Erhebung von Zugriffsdaten und Logfiles: Der Zugriff auf unser Onlineangebot wird in Form von sogenannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die Adresse und der Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum einen zu Sicherheitszwecken eingesetzt werden, z. B. um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken), und zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.',
      cookiesTitle: 'Einsatz von Cookies',
      cookiesDescription: 'Cookies sind kleine Textdateien bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum Beispiel, um den Log-in-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebots zu speichern. Cookies können ferner in Bezug auf unterschiedliche Anliegen Einsatz finden, etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und des Komforts von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.',
      consentNotes: 'Hinweise zur Einwilligung: Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, es sei denn, sie ist laut Gesetzeslage nicht gefordert. Eine Erlaubnis ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung wird ihnen gegenüber deutlich kommuniziert und enthält die Informationen zur jeweiligen Cookie-Nutzung.',
      legalBasisNotes: 'Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: Auf welcher datenschutzrechtlichen Grundlage wir die personenbezogenen Daten der Nutzer mithilfe von Cookies verarbeiten, hängt davon ab, ob wir sie um eine Einwilligung bitten. Falls die Nutzer akzeptieren, ist die Rechtsgrundlage der Verwertung ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verwerteten Daten auf Grundlage unserer berechtigten Interessen (z. B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebots und der Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unseren vertraglichen Verpflichtungen nachzukommen. Zu welchen Zwecken die Cookies von uns verwertet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.',
      storageDuration: 'Speicherdauer: Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:',
      temporaryCookieTitle: 'Temporäre Cookies (auch: Session- oder Sitzungscookies):',
      temporaryCookieDescription: 'Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Onlineangebot verlassen und sein Endgerät (z. B. Browser oder mobile Applikation) geschlossen hat.',
      permanentCookieTitle: 'Permanente Cookies:',
      permanentCookieDescription: 'Permanente Cookies bleiben auch nach dem Schließen des Endgeräts gespeichert. So können beispielsweise der Log-in-Status gespeichert und bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mithilfe von Cookies erhobenen Nutzerdaten zur Reichweitenmessung Verwendung finden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen, sollten sie davon ausgehen, dass diese permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.',
      optOutNotes: 'Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend den gesetzlichen Vorgaben, auch mittels der Privatsphäre-Einstellungen ihres Browsers, erklären.',
      processedDataTypes: 'Verarbeitete Datenarten: Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen).',
      aaffectedPersons: 'Betroffene Personen: Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).',
      legalBases: 'Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).',
      additionalProcessingNotes: 'Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:',
      cookieDataConsentProcessing: 'Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:',
      cookieDataConsentDescription: 'Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und Anbietern eingeholt wird. Dieses Verfahren dient der Einholung, Protokollierung, Verwaltung und dem Widerruf von Einwilligungen, insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren Technologien, die zur Speicherung, zum Auslesen und zur Verarbeitung von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die Nutzung von Cookies und die damit verbundenen Verarbeitungen von Informationen, einschließlich der im Einwilligungs-Management-Verfahren genannten spezifischen Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen. Die Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß den gesetzlichen Anforderungen führen zu können. Die Speicherung erfolgt serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die Einwilligung einem spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen Angaben zu den Anbietern von Einwilligungs-Management-Diensten vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der Einwilligung, den Angaben zum Umfang der Einwilligung (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie Informationen über den Browser, das System und das verwendete Endgerät gespeichert.',
      alegalBasis: 'Rechtsgrundlagen',
      consentLegalBasis: 'Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)',
      sealText: 'Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke',
      imprintTitle: 'Impressum',
      imprintAddress: 'Pascal Megnin Welfenallee 21 21 69181 Leimen',
      contactTitle: 'Kontakt',
      contactInfo: 'Telefon: 015142894562 E-Mail: pascalmegnin1985@gmail.com',
      editorialResponsibility: 'Redaktionell verantwortlich',
      editorialPerson: 'Pascal Megnin',
      source: 'Quelle: <a class="link" href="https://www.e-recht24.de">https://www.e-recht24.de</a>',
      backButton: 'Zurück',
      sendLabel: 'Absenden'  
      }
  };

  private currentLang = 'de';  

  // Funktion zum Umschalten der Sprache
  switchLanguage(lang: string) {
    this.currentLang = lang;
  }

  // Funktion zum Abrufen der aktuellen Sprache
  getCurrentLanguage(): string {
    return this.currentLang;
  }

  // Funktion zum Abrufen der Übersetzung
  getTranslation(key: string): string {
    return this.translations[this.currentLang][key] || key;
  }
}

