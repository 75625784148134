import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, ElementRef, AfterViewInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { TranslationService } from '../translation.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements AfterViewInit {
  constructor(private translationService: TranslationService, private el: ElementRef) { }

  http = inject(HttpClient)

  contactData = {
    name: "",
    email: "",
    message: "",
    agree: false,
    emailsent: false,
  }
  

  post = {
    endPoint: 'https://pascalmegnin.net/sendMail.php',
    body: (payload: any) => JSON.stringify(payload),
    options: {
      headers: {
        'Content-Type': 'text/plain',
        responseType: 'text',
      },
    },
  };
  
  onSubmit(ngForm: NgForm) {
    if (ngForm.submitted && ngForm.form.valid && this.contactData.agree) {
      this.http.post(this.post.endPoint, this.post.body(this.contactData))
        .subscribe({
          next: (response) => {
            this.contactData.emailsent = true; 
            setTimeout(() => {
              ngForm.resetForm();
              this.contactData.emailsent = false;
            }, 3000);
             },
          error: (error) => {
            console.error(error);
            this.contactData.emailsent = false; 
          },
          complete: () => console.info('send post complete'), 
        });
    }
  }

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('.container');
  
    const observerOptions = {
      threshold: 0.1 
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn'); 
        } else {
          entry.target.classList.remove('fadeIn'); 
          
        }
      });
    }, observerOptions);
  
    elements.forEach((element: HTMLElement) => {
      observer.observe(element); 
    });
  }
  

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }

}
