import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { TranslationService } from '../translation.service'; 
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'] 
})
export class MainComponent implements AfterViewInit {

  constructor(private translationService: TranslationService, private el: ElementRef, private router: Router) { }

  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault(); 
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); 
        } else {
          console.warn(`Section with ID '${sectionId}' not found.`);
        }
      }, 100); 
    });
  }

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('.format, .vectorshape, .talkSpan'); 
  
    const observerOptions = {
      threshold: 0.1 
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn'); 
  
          if (entry.target.classList.contains('talkSpan')) {
            entry.target.classList.add('wobble');
            setTimeout(() => {
              entry.target.classList.remove('wobble');
            }, 2000);
          }
  
        } else {
          entry.target.classList.remove('fadeIn'); 
  
          if (entry.target.classList.contains('talkSpan')) {
            entry.target.classList.remove('wobble');
          }
        }
      });
    }, observerOptions);
  
    elements.forEach((element: HTMLElement) => {
      observer.observe(element); 
    });
  }
  
  
  

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }
}
