import { Routes } from '@angular/router';
import { ImpressumComponent } from './impressum/impressum.component'; 
import { MainmainComponent } from './mainmain/mainmain.component';
import { DatenschatzComponent } from './datenschatz/datenschatz.component';
import { ProjectsComponent } from './projects/projects.component';
import { SkillsComponent } from './skills/skills.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { ContactComponent } from './contact/contact.component';

export const routes: Routes = [
  { path: 'impressum', component: ImpressumComponent },  
  { path: 'datenschatz', component: DatenschatzComponent },  
  { path: '', component: MainmainComponent },
  { path: 'portfolio', component: ProjectsComponent },
  { path: 'skills', component: SkillsComponent },
  { path: 'about', component: AboutmeComponent },
  { path: 'contact', component: ContactComponent }, 
  ];


