import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { TranslationService } from '../translation.service';

@Component({
  selector: 'app-aboutme',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aboutme.component.html',
  styleUrl: './aboutme.component.scss'
})
export class AboutmeComponent implements AfterViewInit {
  constructor(private translationService: TranslationService, private el: ElementRef) { }

  ngAfterViewInit(): void {
    const images = this.el.nativeElement.querySelectorAll('.meAboutPicture');
  
    const observerOptions = {
      threshold: 0.1 
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn'); 
        } else {
          entry.target.classList.remove('fadeIn'); 
          }
      });
    }, observerOptions);
  
    images.forEach((image: HTMLElement) => {
      observer.observe(image); 
    });
  }
  

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }
}
