import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit  } from '@angular/core';
import { TranslationService } from '../translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-skills',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './skills.component.html',
  styleUrl: './skills.component.scss'
})
export class SkillsComponent implements AfterViewInit {
  constructor(private translationService: TranslationService, private el: ElementRef, private router: Router) { }

  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault(); 
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); 
        } else {
          console.warn(`Section with ID '${sectionId}' not found.`);
        }
      }, 100); 
    });
  }

  ngAfterViewInit(): void {
    const images = this.el.nativeElement.querySelectorAll('.orderSection, .contactMe , .skillPicture');
  
    const observerOptions = {
      threshold: 0.1 
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn'); 
  
          if (entry.target.classList.contains('contactMe') || entry.target.classList.contains('skillPicture')) {
            entry.target.classList.add('wobble');
            
            setTimeout(() => {
              entry.target.classList.remove('wobble');
            }, 2000); 
          } 
  
        } else {
          entry.target.classList.remove('fadeIn'); 
          
          if (entry.target.classList.contains('contactMe') || entry.target.classList.contains('skillPicture')) {
            entry.target.classList.remove('wobble');
          } 
        }
      });
    }, observerOptions);
  
    images.forEach((image: HTMLElement) => {
      observer.observe(image); 
    });
  }
  
  

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }
}
