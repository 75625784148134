<div class="contactSection">
    <div class="headlineContact">
        <div class="lineDiv1"></div>
        <span class="contactSpan">Contact</span>
        <div class="lineDiv2"></div>
    </div>
    <div class="inputSection">
        <div class="textSpanSection">
            <span class="contactSectionSpan headlineSectionContact">Got a problem to solve?</span>
            <span class="contactSectionSpan headlineSectionContact2" [innerHTML]="getTranslation('contactMessage1')"></span>
            <span class="contactSectionSpan headlineSectionContact3" [innerHTML]="getTranslation('contactMessage2')"></span>
        </div>
        <div class="container">
            <form (ngSubmit)="onSubmit(contactForm)" class="formClass" #contactForm="ngForm">
                <input #name="ngModel" [(ngModel)]="contactData.name" class="inputStandard inputClass2" type="text" id="name" name="name" placeholder="Your name.." required [class.invalid]="name.invalid && name.touched" [attr.placeholder]="name.invalid && name.touched ? getTranslation('nameRequired') : 'Your name..'">

                <input #email="ngModel" [(ngModel)]="contactData.email" class="inputStandard inputClass2" type="email" id="email" name="email" placeholder="Your mail-adress.." required pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}" [class.invalid]="email.invalid && email.touched"
                    [attr.placeholder]="email.invalid && email.touched ? getTranslation('emailRequired') : 'Your mail-adress..'">

                <textarea #message="ngModel" [(ngModel)]="contactData.message" class="inputStandard inputClass3" id="message" name="message" placeholder="Write something.." required style="height:200px" [class.invalid]="message.invalid && message.touched" [attr.placeholder]="message.invalid && message.touched ? getTranslation('messageRequired') : 'Write something..'"></textarea>

                <div class="checkbox">
                    <input class="clickbox" type="checkbox" id="exampleCheckbox" name="exampleCheckbox" [(ngModel)]="contactData.agree" required>
                    <label for="exampleCheckbox" class="custom-checkbox"></label>
                    <p>{{ getTranslation('privacyPolicyAgreement') }}</p>
                </div>
                <input class="inputButton" type="submit" value="{{ getTranslation('sendLabel') }}" [disabled]="!contactForm.valid || !contactData.agree">
                <span class="messageSpan sucessSpan" *ngIf="contactForm.submitted && contactForm.valid && contactData.agree && contactData.emailsent">
                    {{ getTranslation('successMessage') }}
                </span>
            </form>

        </div>
    </div>
    <a class="up" href="#main"><img src="assets/img/upbutton.png" alt=""></a>
</div>