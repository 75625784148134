<div id="main" class="main">
    <div class="format">
        <div class="pictureArea">
            <img class="portrait" src="assets/img/WebPage.png" alt="">

        </div>
        <div class="textArea">

            <span class="changeSpan">{{ getTranslation('introduction') }}</span>
            <span class="nameSpan">Pascal Megnin</span>
            <span class="titleSpan">FRONTEND DEVELOPER</span>
            <a class="talkSpan" href="#contact" (click)="scrollToSection($event, 'contact')">Let's talk!</a>

        </div>
    </div>
    <div class="socialLogos">
        <div class="logoSectionNew">
            <div class="pinkLine"></div>
            <a href="https://github.com/Pascalina1985?tab=repositories" target="_blank">
                <img class="iconPicture" src="assets/img/git.png" alt="GitHub">
            </a>
            <a href="mailto:pascalmegnin1985@gmail.com">
                <img class="iconPicture" src="assets/img/mail.png" alt="Email">
            </a>
            <a href="https://www.xing.com/profile/Pascal_Megnin/web_profiles" target="_blank">
                <img class="iconPicture" src="assets/img/linkedin.png" alt="LinkedIn">
            </a>
            <a class="straightEmail" href="mailto:pascalmegnin1985@gmail.com">pascalmegnin1985&#64;gmail.com</a>
        </div>
        <img class="scrollPic" src="assets/img/Scrolldown.png" alt="">
    </div>
    <div class="vectorshape"></div>
</div>