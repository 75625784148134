<div class="header">
    <div class="newFormat">
        <span class="name">Pascal Megnin</span>
        <button class="burger-icon" (click)="toggleMenu()"><img class="burger-icon-pic" src="assets/img/burger menu.png" alt=""></button>
        <div class="linksResponsive">
            <button class="formatterButton" (click)="switchLanguage('en')">
            <img [ngClass]="{'active-language': selectedLanguage === 'en'}" class="spracheButton" src="assets/img/englisch.jpg" alt="English">
          </button>
            <button class="formatterButton" (click)="switchLanguage('de')">
            <img [ngClass]="{'active-language': selectedLanguage === 'de'}" class="spracheButton" src="assets/img/deutsch.jpg" alt="Deutsch">
          </button>
            <a class="linksClass" href="#contact" (click)="scrollToSection($event, 'contact')">{{ getTranslation('contact') }}</a>
            <a class="linksClass" href="#about" (click)="scrollToSection($event, 'about')">About me</a>
            <a class="linksClass" href="#skills" (click)="scrollToSection($event, 'skills')">Skills</a>
            <a class="linksClass" href="#portfolio" (click)="scrollToSection($event, 'portfolio')">Portfolio</a>
        </div>
    </div>
</div>
<div class="overlay" [class.active]="menuOpen">
    <div class="overlay-content">
        <div class="buttonOverlay">
            <button class="formatterButton" (click)="switchLanguage('en'); closeMenu('switch')">
            <img [ngClass]="{'active-language': selectedLanguage === 'en'}" class="spracheButton" src="assets/img/englisch.jpg" alt="English">
          </button>
            <button class="formatterButton" (click)="switchLanguage('de'); closeMenu('switch')">
            <img [ngClass]="{'active-language': selectedLanguage === 'de'}" class="spracheButton" src="assets/img/deutsch.jpg" alt="Deutsch">
          </button>
        </div>
        <a href="#contact" (click)="scrollToSection($event, 'contact'); closeMenu('direct')">{{ getTranslation('contact') }}</a>
        <a href="#about" (click)="scrollToSection($event, 'about'); closeMenu('direct')">About me</a>
        <a href="#skills" (click)="scrollToSection($event, 'skills'); closeMenu('direct')">Skills</a>
        <a href="#portfolio" (click)="scrollToSection($event, 'portfolio'); closeMenu('direct')">Portfolio</a>
    </div>
</div>