import { CommonModule } from '@angular/common';
import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { TranslationService } from '../translation.service';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'] 
})
export class ProjectsComponent implements AfterViewInit {
  constructor(private translationService: TranslationService, private el: ElementRef) { }

  ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('.projectPic, .liveTestButton, .gitButton');
  
    const observerOptions = {
      threshold: 0.1 
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fadeIn'); 
  
          if (entry.target.classList.contains('liveTestButton') || entry.target.classList.contains('gitButton') || entry.target.classList.contains('projectPic')) {
            entry.target.classList.add('wobble');
            
            setTimeout(() => {
              entry.target.classList.remove('wobble');
            }, 2000); 
          }
  
        } else {
          entry.target.classList.remove('fadeIn');
          
          if (entry.target.classList.contains('liveTestButton') || entry.target.classList.contains('gitButton') || entry.target.classList.contains('projectPic')) {
            entry.target.classList.remove('wobble');
          }
        }
      });
    }, observerOptions);
  
    elements.forEach((element: HTMLElement) => {
      observer.observe(element); 
    });
  }
  
  

  getTranslation(key: string): string {
    return this.translationService.getTranslation(key);
  }

  switchLanguage(lang: string) {
    this.translationService.switchLanguage(lang);
  }
}
