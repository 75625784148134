<div class="impressum">
    <button (click)="goToHome()" class="backButton">{{ getTranslation('backButton') }}</button>
    <h1 class="texte">{{ getTranslation('imprintTitle') }}</h1>
    <p class="texte">{{ getTranslation('imprintAddress') }}</p>

    <h2 class="texte">{{ getTranslation('contactTitle') }}</h2>
    <p class="texte">{{ getTranslation('contactInfo') }}</p>

    <h2 class="texte">{{ getTranslation('editorialResponsibility') }}</h2>
    <p class="texte">{{ getTranslation('editorialPerson') }}</p>

    <p class="texte">{{ getTranslation('source') }}</p>
</div>